import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import DOMPurify from "dompurify";

const EmployerApplicantInformation = () => {
  const params = useParams();

  const [vacancyInformation, setVacancyInformation] = useState(null);
  const navigate = useNavigate();

  const getVacancy = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
    );
    if (data.success === true) {
      setVacancyInformation(data.singleVacancy);
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-md overflow-hidden">
        {vacancyInformation ? (
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-2">
              {vacancyInformation.jobTitle}
            </h2>

            <p className="text-gray-600 mb-2">
              {vacancyInformation.companyName}
            </p>

            <p className="text-gray-600 mb-4">
              {vacancyInformation.experiencelevel.value === 0
                ? "Entry level"
                : `${vacancyInformation.experiencelevel.value} ${vacancyInformation.experiencelevel.unit}`}
            </p>
            <p className="text-gray-600 mb-4">{vacancyInformation.jobType}</p>
            <p className="text-gray-600 mb-4">
              {vacancyInformation.skills.join(", ")}
            </p>
            <div className="mb-4 text-start">
              <h3 className="text-lg font-semibold">Job Description</h3>
              <div
                className="job-description text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(vacancyInformation.jobDescription),
                }}
              ></div>
            </div>
            <p className="py-5">
              Active from {formatDate(vacancyInformation?.startDate)} to{" "}
              {formatDate(vacancyInformation?.endDate)}
            </p>
            <button
              className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
              onClick={() =>
                navigate(
                  `/dashboard/employer/showResult/${vacancyInformation._id}`
                )
              }
            >
              Show Result
            </button>
          </div>
        ) : (
          <div>Loading ...</div>
        )}
      </div>
    </>
  );
};

export default EmployerApplicantInformation;
