import React from "react";
import { NavLink } from "react-router-dom";

const CandidateSidebar = () => {
  return (
    <div className="w-64 bg-gray-800 shadow-md">
      <div className="p-4 text-white text-xl font-bold text-center">
        Dashboard
      </div>
      <ul className="mt-6 flex flex-col">
        <NavLink
          to="/dashboard/candidate"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/dashboard/candidate/profile"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Profile
        </NavLink>
        <NavLink
          to="/dashboard/candidate/applicant"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Applicant
        </NavLink>
        <NavLink
          to="/dashboard/candidate/setting"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Setting
        </NavLink>
      </ul>
    </div>
  );
};

export default CandidateSidebar;
