import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const EmployerVacancyInformation = () => {
  const [vacancy, setVacancy] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [shareLink, setShareLink] = useState("");
  const navigate = useNavigate();

  const getCompanyVacancy = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/activeCompanyVacancies`
      );
      console.log(data);
      if (data?.success) {
        setVacancy(data?.activeVacancies);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getCompanyVacancy();
  }, [getCompanyVacancy]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + " ........................";
  };

  const handleShareLink = (vacancyId) => {
    const link = `${window.location.origin}/dashboard/vacancyInfo/${vacancyId}`;
    setShareLink(link);
    setModalOpen(true);
  };

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(shareLink)
      .then(() => {
        setModalOpen(false);
        toast.success("Link Copied Successfully");
      })
      .catch((err) => {
        console.error("Failed to copy link: ", err);
      });
  };

  const closeModal = () => setModalOpen(false);
  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="max-w-2xl mx-auto rounded-md overflow-hidden">
        <div className="text-lg text-gray-700 mb-4">
          <strong>Job</strong>
        </div>
        {vacancy?.length > 0 ? (
          vacancy.map((vac) => (
            <div className="p-6 border my-5" key={vac._id}>
              <h2 className="text-2xl font-bold mb-2">{vac.companyName}</h2>
              <h2 className="text-2xl font-bold mb-2">
                {vac.experiencelevel.value === 0
                  ? "Entry level"
                  : `${vac.experiencelevel.value} ${vac.experiencelevel.unit}`}
              </h2>
              <p className="text-gray-600 mb-2">
                Skills: {vac.skills.join(", ")}
              </p>
              <p className="text-gray-600 mb-2">{vac.jobTitle}</p>
              <p className="text-gray-600 mb-2">{vac.jobType}</p>
              <p
                className="text-gray-700 mb-4"
                dangerouslySetInnerHTML={{
                  __html: truncateText(vac.jobDescription, 20),
                }}
              ></p>
              <p className="py-5">
                Active from {formatDate(vac.startDate)} to{" "}
                {formatDate(vac.endDate)}
              </p>

              <div>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
                  onClick={() => handleShareLink(vac._id)}
                >
                  Share Link
                </button>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
                  onClick={() =>
                    navigate(
                      `/dashboard/employer/vacancyInfromation/${vac._id}`
                    )
                  }
                >
                  See More
                </button>

                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
                  onClick={() =>
                    navigate(`/dashboard/employer/showResult/${vac._id}`)
                  }
                >
                  Show Result
                </button>
              </div>
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>

      {/* Modal Component */}
      {modalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-700 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg  mx-auto">
            <h2 className="text-lg font-bold mb-4">Shareable Link</h2>
            <p className="mb-4">{shareLink}</p>
            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
              onClick={copyToClipboard}
            >
              Copy Link
            </button>
            <button
              className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-700 transition duration-300"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default EmployerVacancyInformation;
