import React from "react";
import EmployerSidebar from "../../components/employer/EmployerSidebar";
import Header from "../../components/Layout/Header/Header";
import EmployerCreateVacancy from "../../components/employer/EmployerCreateVacancy";

const EmployerCreateVacancyPage = () => {
  return (
    <div className="flex min-h-screen">
      <EmployerSidebar />
      <div className="flex flex-1 flex-col ">
        <Header />
        <EmployerCreateVacancy />
      </div>
    </div>
  );
};

export default EmployerCreateVacancyPage;
