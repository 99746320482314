import axios from "axios";
import React, { useState } from "react";
import Modal from "react-modal";
import { useAuth } from "../context/auth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { customStyles } from "../styles/modalStyles";

const DeleteAccount = () => {
  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const [auth, setAuth] = useAuth();
  const navigate = useNavigate();
  const openDeleteModal = () => {
    setDeleteModalIsOpen(true);
  };
  const closeDeleteModal = () => {
    setDeleteModalIsOpen(false);
  };

  const handleDelete = async () => {
    try {
      const { data } = await axios.delete(
        `${process.env.REACT_APP_API_URL}/api/v1/user/deleteUser`
      );
      if (data.success) {
        closeDeleteModal();
        setAuth({
          ...auth,
          user: null,
          token: "",
        });
        localStorage.removeItem("auth");
        toast.success("Account Delete Successfully");
        navigate("/login");
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="b max-w-3xl mx-auto space-y-4">
      <h2 className="text-2xl font-semibold mb-4 border-b-2 border-gray-200 pb-2">
        Danger
      </h2>
      <div className="flex justify-evenly p-5 text-center b max-w-3xl bg-slate-200 mx-auto space-y-4">
        <div className="p-6 border rounded-md text-xl">
          Are you sure to Delete the Account?
        </div>
        <button
          className="bg-red-600 p-4 rounded-lg hover:bg-red-800 text-white"
          onClick={() => openDeleteModal()}
        >
          Delete Account
        </button>
      </div>
      <Modal
        isOpen={deleteModalIsOpen}
        onRequestClose={closeDeleteModal}
        contentLabel="Delete Account"
        style={customStyles}
      >
        <h2 className="text-2xl font-bold mb-6">Delete Account</h2>
        <p>
          Are you sure you want to delete the company? This action cannot be
          undone.
        </p>

        <div className="flex items-center justify-between">
          <button
            type="button"
            onClick={handleDelete}
            className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Delete
          </button>
          <button
            type="button"
            onClick={closeDeleteModal}
            className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
          >
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteAccount;
