import React, { useState } from "react";
import NavbarLogoImage from "../../../assets/images/logo.png";
import NavbarLogoImage2 from "../../../assets/images/logoGlow.png";
import { NavLink, Link } from "react-router-dom";
import { AiOutlineMenu, AiOutlineClose } from "react-icons/ai";
import "./Header.css";
import { useAuth } from "../../../context/auth";
import { toast } from "react-toastify";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

const adminMenu = [
  {
    name: "AdminDashboard",
    to: "/dashboard/admin",
  },
  {
    name: "Vacancy",
    to: "#",
  },
];
const employerMenu = [
  {
    name: "EmployerDashboard",
    to: "/dashboard/employer",
  },
  {
    name: "Vacancy",
    to: "#",
  },
];
const candidateMenu = [
  {
    name: "CandidateDashboard",
    to: "/dashboard/candidate",
  },
  {
    name: "Profile",
    to: "/dashboard/candidate/profile",
  },
];
const menuItems = [
  { name: "Selection", to: "/" },
  { name: "FAQ", to: "/faq" },
];

function getMenuByRole(role) {
  switch (role) {
    case "admin":
      return adminMenu;
    case "employer":
      return employerMenu;
    case "candidate":
      return candidateMenu;
    default:
      return [];
  }
}
const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [auth, setAuth] = useAuth();

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    setAuth({
      ...auth,
      user: null,
      token: "",
    });
    localStorage.removeItem("auth");
    toast.success("Logout Successfully");
  };
  const products = getMenuByRole(auth?.user?.role);

  return (
    <>
      <header className="absolute inset-x-0 top-0 z-50 text-white">
        <div className="flex justify-between items-center ">
          <Link to="/home" className=" text-white  flex items-center">
            <div className="animate-imageSwap" style={{ width: "200px" }}>
              <img src={NavbarLogoImage} alt="Logo 1" />
              <img src={NavbarLogoImage2} alt="Logo 2" />
            </div>
          </Link>
          {/* Breadcrumb icon for mobile */}
          <div className="md:hidden">
            <button onClick={toggleMenu}>
              <AiOutlineMenu className="w-8 h-8 text-primary" />
            </button>
          </div>
          {/* Desktop Menu - Centered Navigation */}
          {/* Sign In Button */}
          <div className="hidden font-futura md:flex text-2xl space-x-8 font-bold text-primary  items-center">
            <NavLink
              to="/home"
              className={({ isActive }) =>
                `  ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">H</span>OME
            </NavLink>
            <NavLink
              to="/about-us"
              className={({ isActive }) =>
                ` ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">A</span>BOUT US
            </NavLink>
            <NavLink
              to="/service"
              className={({ isActive }) =>
                ` ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">S</span>ERVICES
            </NavLink>

            <Popover className="relative">
              <PopoverButton className="flex items-center gap-x-1 font-semibold leading-6">
                <span className="text-3xl font-cinzel">M</span>ore
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none"
                />
              </PopoverButton>
              <PopoverPanel
                transition
                className="absolute left-0 top-full z-10 mt-3 rounded-3xl shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in "
              >
                <div className="p-4">
                  {menuItems.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-2xl leading-6 hover:bg-gray-50 bg-gray-900"
                    >
                      <div className="flex-auto">
                        <NavLink to={item.to} className="block font-semibold">
                          {item.name}
                          <span className="absolute inset-0" />
                        </NavLink>
                      </div>
                    </div>
                  ))}
                </div>
              </PopoverPanel>
            </Popover>
            {/* <NavLink
              to="/voice"
              className={({ isActive }) =>
                `  ${
                  isActive ? "text-green-400" : "text-primary"
                } hover:text-green-400`
              }
            >
              <span className="text-3xl font-cinzel">V</span>OICE
            </NavLink> */}
            {auth.user && (
              <>
                <Popover className="relative">
                  <PopoverButton className="flex items-center gap-x-1 font-semibold leading-6">
                    {auth?.user?.firstName}
                    <ChevronDownIcon
                      aria-hidden="true"
                      className="h-5 w-5 flex-none"
                    />
                  </PopoverButton>
                  <PopoverPanel
                    transition
                    className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl  shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="p-4">
                      {products.map((item) => (
                        <div
                          key={item.name}
                          className="group relative flex items-center gap-x-6 rounded-lg p-4 text-2xl leading-6 hover:bg-gray-50 bg-gray-900"
                        >
                          <div className="flex-auto">
                            <NavLink
                              to={item.to}
                              className="block font-semibold"
                            >
                              {item.name}
                              <span className="absolute inset-0" />
                            </NavLink>
                          </div>
                        </div>
                      ))}
                    </div>
                  </PopoverPanel>
                </Popover>
              </>
            )}
            <Popover className="relative">
              <PopoverButton className="custom-button  border border-rounded flex items-center gap-x-1 font-semibold leading-6">
                <span className="text-3xl font-cinzel">P</span>ortal
                <ChevronDownIcon
                  aria-hidden="true"
                  className="h-5 w-5 flex-none"
                />
              </PopoverButton>
              <PopoverPanel
                transition
                className="absolute left-0 top-full z-10 mt-3 rounded-3xl shadow-lg ring-1 ring-gray-900/5 transition data-[closed]:translate-y-1 data-[closed]:opacity-0 data-[enter]:duration-200 data-[leave]:duration-150 data-[enter]:ease-out data-[leave]:ease-in "
              >
                {!auth?.user ? (
                  <>
                    <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-2xl leading-6 hover:bg-gray-50 bg-gray-900">
                      <div className="flex-auto">
                        <NavLink to="/signup" className="block font-semibold">
                          Sign Up
                          <span className="absolute inset-0" />
                        </NavLink>
                      </div>
                    </div>
                    <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-2xl leading-6 hover:bg-gray-50 bg-gray-900">
                      <div className="flex-auto">
                        <NavLink to="/login" className=" leading-6">
                          Login <span aria-hidden="true">&rarr;</span>
                        </NavLink>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="group relative flex items-center gap-x-6 rounded-lg p-4 text-2xl leading-6 hover:bg-gray-50 bg-gray-900">
                      <div className="flex-auto">
                        <NavLink
                          onClick={handleLogout}
                          to="/login"
                          className=" leading-6"
                        >
                          Log out <span aria-hidden="true">&rarr;</span>
                        </NavLink>
                      </div>
                    </div>
                  </>
                )}
              </PopoverPanel>
            </Popover>
          </div>
          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="fixed inset-0 bg-black bg-opacity-80 z-40">
              <div className="fixed left-0 top-0 w-3/4 h-full p-6 z-20 bg-[rgba(10, 10, 10, 0.80)] rounded-tr-2xl shadow-text-custom">
                <div className="flex flex-row justify-end">
                  <button className="text-primary mb-4" onClick={toggleMenu}>
                    <AiOutlineClose className="text-primary" />
                  </button>
                </div>
                <nav className="flex flex-col space-y-4 text-primary">
                  <NavLink
                    to="/home"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    HOME
                  </NavLink>
                  <NavLink
                    to="/about-us"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    ABOUT US
                  </NavLink>
                  <NavLink
                    to="/service"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    SERVICES
                  </NavLink>

                  <NavLink
                    to="/"
                    className={({ isActive }) =>
                      `font-cinzel ${
                        isActive ? "text-green-400" : "text-primary"
                      }`
                    }
                    onClick={toggleMenu}
                  >
                    MORE
                  </NavLink>

                  <button className="custom-button ">Sign in</button>
                </nav>
              </div>
            </div>
          )}
        </div>
      </header>
    </>
  );
};

export default Header;
