import React from "react";
import employerVision from "../../../assets/vision/employerVision.png";
import applicantVision from "../../../assets/vision/applicantVision.png";
import { useUserRole } from "../../../context/userRoleContext";
import dottedLine from "../../../assets/selection/dottedLine.png";
import straightLine from "../../../assets/hero/straightLine.png";

const Vision = () => {
  const { userRole } = useUserRole();
  const vision = userRole === "recruiter" ? employerVision : applicantVision;

  return (
    <div
      className="flex items-center justify-center bg-cover bg-center h-screen"
      style={{
        backgroundImage: `url(${vision})`,
      }}
    >
      <div
        className="container max-w-screen-xl mx-auto text-white p-10 rounded-lg"
        data-aos="fade-zoom-in"
      >
        <div className="flex flex-col ">
          <div className="flex flex-col mb-5 font-semibold">
            <img src={dottedLine} alt="" className="py-4 w-1/6" />
            <img src={straightLine} alt="" className="py-4 w-1/4" />
            <h1 className="text-white text-5xl">
              <span className="font-cinzel text-6xl">O</span>UR{" "}
              <span className="font-cinzel text-6xl">V</span>ISION
            </h1>
          </div>
          <div className="flex flex-col text-2xl max-w-3xl">
            {userRole === "recruiter" ? (
              <>
                <p className="mb-4">
                  At WizardHire, our mission is to remove bias from hiring and
                  create a fair, transparent recruitment process.
                </p>
                <p className="mb-4">
                  We use AI technology to help employers find the best
                  candidates based on skills and potential, ensuring diversity
                  and inclusivity in every hire.
                </p>
                <p>
                  Our goal is to make hiring efficient and accurate, so
                  businesses can build strong, innovative teams while giving
                  every candidate an equal chance to succeed.
                </p>
              </>
            ) : (
              <>
                <p className="mb-4">
                  At WizardHire, our mission is to give every applicant an equal
                  and fair chance to showcase their skills.
                </p>
                <p className="mb-4">
                  We use AI-driven technology to remove bias from the hiring
                  process, ensuring that your qualifications and potential are
                  what matter most.
                </p>
                <p>
                  Our platform is designed to connect you with opportunities
                  where your talents can shine, helping you advance your career
                  based on merit, not perceptions.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Vision;
