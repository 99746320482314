import React from "react";
import { useNavigate } from "react-router-dom";
import { useUserRole } from "../../context/userRoleContext";
// import DarkMode from "../../components/Layout/Header/DarkMode";

import logoHat from "../../assets/selection/logoHat.png";
import logoHatGlow from "../../assets/selection/logoHatGlow.png";
import selection from "../../assets/selection/selectionPage.png";
import dottedLine from "../../assets/selection/dottedLine.png";

import "./Selection.css";

const SelectionPage = () => {
  const navigate = useNavigate();
  const { updateRole } = useUserRole();

  const handleSelect = (role) => {
    if (role) {
      updateRole(role);
      navigate("/home");
    }
  };

  return (
    <div
      className="bg-neutral-dark dark:bg-neutral-light text-white font-futura"
      style={{
        backgroundImage: `url(${selection})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        minHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center", // Vertically center the content
        alignItems: "center", // Horizontally center the content
      }}
    >
      {/* Header */}
      {/* <div className="flex justify-end p-4"> <DarkMode /> </div> */}

      {/* Main Section */}
      <div className="container max-w-screen-xl mx-auto flex flex-col items-center justify-center">
        {/* Logo Section */}
        <div className="text-center dark:text-primary-dark">
          <div className="flex justify-center items-center">
            <div
              className="relative animate-pulse"
              style={{ width: "80px", height: "80px" }}
            >
              <img
                src={logoHat}
                alt="Logo Hat"
                className="absolute w-full h-full"
              />
              <img
                src={logoHatGlow}
                alt="Logo Hat Glow"
                className="absolute w-full h-full opacity-80"
              />
            </div>
          </div>

          {/* Line and Title Section */}
          <div className="flex justify-center items-center mt-4">
            <div className="flex items-center justify-center space-x-2 md:ml-[-150px]">
              <img
                src={dottedLine}
                alt="dotted line"
                className="w-40 md:w-56"
              />
              <h2 className="text-3xl font-bold">
                <span className="text-4xl font-cinzel">W</span>HICH{" "}
                <span className="text-4xl font-cinzel">O</span>NE
              </h2>
            </div>
          </div>

          {/* Line and Title Section */}
          <div className="flex justify-center items-center">
            <div className="flex items-center justify-center space-x-2 md:mr-[-300px]">
              <h2 className="text-3xl font-bold">
                <span className="text-4xl font-cinzel">R</span>EPRESENTS{" "}
                <span className="text-4xl font-cinzel">Y</span>OU?
              </h2>
              <img
                src={dottedLine}
                alt="dotted line"
                className="w-40 md:w-56"
              />
            </div>
          </div>
        </div>

        {/* Role Selection Buttons */}
        <div className="flex flex-col md:flex-row w-full justify-center items-center space-y-6 md:space-y-0 md:space-x-6">
          {/* Employer Button */}
          <button
            className="group flex flex-col items-center p-6 rounded-lg transform hover:scale-105 transition-all duration-300 text-center w-full md:w-[45%] min-h-[300px] flex-grow"
            onClick={() => handleSelect("recruiter")}
          >
            <h3 className="text-2xl font-bold mb-2 bg-[#182851]/50 border border-white rounded-2xl py-2 px-4 text-white group-hover:scale-110 transition-transform duration-300">
              <span className="text-2xl font-cinzel">A</span>N{" "}
              <span className="text-2xl font-cinzel">E</span>MPLOYER
            </h3>
            <div className="w-[50%] bg-black/80 border border-white rounded-lg text-white text-lg">
              <div className="bg-[#182851]/50 h-6 rounded-t-lg"></div>
              <div className="p-6 flex-grow">
                <p className="mb-4">
                  WizardHire is transforming recruitment with cutting-edge AI.
                </p>
                <p className="mb-4">
                  Our platform streamlines your hiring process, delivering top
                  talent faster and reducing bias with custom AI built for your
                  needs. Save time, hire smarter, and watch your workforce grow!
                </p>
              </div>
            </div>
          </button>

          {/* Applicant Button */}
          <button
            className="group flex flex-col items-center p-6 transform hover:scale-105 transition-all duration-300 text-center w-full md:w-[45%] min-h-[300px] flex-grow"
            onClick={() => handleSelect("candidate")}
          >
            <h3 className="text-2xl font-bold mb-2 bg-[#182851]/50 border border-white rounded-2xl py-2 px-4 text-white group-hover:scale-110 transition-transform duration-300">
              <span className="text-2xl font-cinzel">A</span>N{" "}
              <span className="text-2xl font-cinzel">A</span>PPLICANT
            </h3>
            <div className="w-[50%] bg-black/80 border border-white rounded-lg text-white">
              <div className="bg-[#182851]/50 h-6 rounded-t-lg"></div>
              <div className="p-6 text-lg flex-grow">
                <p className="mb-4">
                  WizardHire makes applying for jobs easy, fun, and fair.
                </p>
                <p className="mb-4">
                  Using AI, we provide real-time feedback and ensure every
                  application is reviewed thoroughly. Get your dream job faster
                  with a platform built to give everyone an equal shot!
                </p>
              </div>
            </div>
          </button>
        </div>
      </div>
    </div>
  );
};

export default SelectionPage;
