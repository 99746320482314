import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import DOMPurify from "dompurify";

const VoiceInterview = () => {
  const params = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [jobDescription, setJobDescription] = useState("");
  const [userResponse, setUserResponse] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [jobTitle, setJobTitle] = useState("");
  const [answerCount, setAnswerCount] = useState(0);
  const [interviewResult, setInterviewResult] = useState("");
  const [result, setResult] = useState(null);
  const [feedBacks, setFeedBacks] = useState("");
  const [applicantId, setApplicantId] = useState(
    location.state?.applicantId || ""
  );
  const [resume, setResume] = useState(location.state?.resume || "");

  const vacancyId = params.id;
  const answerCountRef = useRef(0);
  const recognitionRef = useRef(null);
  const [isListening, setIsListening] = useState(false);

  // Ref to store the selected voice
  const selectedVoiceRef = useRef(null);
  useEffect(() => {
    const synth = window.speechSynthesis;

    const updateDefaultVoice = () => {
      const voices = synth.getVoices();
      const selectedVoice =
        voices.find((voice) => voice.name === "Google UK English Male") ||
        voices.find((voice) => voice.lang.startsWith("en"));
      if (selectedVoice) selectedVoiceRef.current = selectedVoice;
    };

    if (synth.getVoices().length > 0) {
      updateDefaultVoice();
    } else {
      synth.onvoiceschanged = updateDefaultVoice;
    }

    return () => {
      synth.onvoiceschanged = null;
    };
  }, []);

  // Enhanced text-to-speech function
  const speakText = (text) => {
    const synth = window.speechSynthesis;
    console.log("Speak Text", text);

    // Cancel any ongoing speech
    if (synth.speaking) {
      synth.cancel();
    }

    const utterance = new SpeechSynthesisUtterance(text);
    const selectedVoice = selectedVoiceRef.current;

    if (selectedVoice) {
      console.log("Using default voice:", selectedVoice.name);
      utterance.voice = selectedVoice;
      utterance.pitch = 1.0; // Natural pitch
      utterance.rate = 1.0; // Natural speaking rate
      utterance.volume = 1.0; // Full volume
    }

    utterance.onend = () => {
      console.log("Finished speaking");
    };

    utterance.onerror = (event) => {
      console.error("Speech synthesis error:", event);
      toast.error("Error in speech synthesis");
    };

    synth.speak(utterance);
  };

  // Initialize Speech Recognition
  // Initialize Speech Recognition with Permission Handling
  useEffect(() => {
    const SpeechRecognition =
      window.SpeechRecognition || window.webkitSpeechRecognition;

    // Explicitly request microphone permission
    const requestMicPermission = async () => {
      try {
        await navigator.mediaDevices.getUserMedia({ audio: true });
        console.log("Microphone permission granted.");
      } catch (error) {
        console.error("Microphone permission denied:", error);
        toast.error("Microphone access is required for the interview.");
      }
    };

    requestMicPermission();

    if (SpeechRecognition) {
      const recognition = new SpeechRecognition();
      recognition.lang = "en-US";
      recognition.interimResults = false;
      recognition.maxAlternatives = 1;
      recognitionRef.current = recognition;

      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        setUserResponse(transcript);
        handleAskQuestion(transcript);
      };

      recognition.onerror = (event) => {
        toast.error("Speech recognition error: " + event?.error);
        setIsListening(false);
      };

      recognition.onend = () => setIsListening(false);
    }
  }, []);

  // Cleanup function
  useEffect(() => {
    return () => {
      if (window.speechSynthesis.speaking) {
        window.speechSynthesis.cancel();
      }
    };
  }, [navigate]);

  const startListening = () => {
    if (recognitionRef?.current) {
      setIsListening(true);
      recognitionRef?.current.start();
    }
  };

  const completeInterview = async () => {
    try {
      const { data: evaluationData } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/evaluate`,
        { jobDescription, chatHistory }
      );

      const evaluation = evaluationData?.evaluation || "No evaluation";
      const feedback = evaluationData?.summaryFeedback || "No feedback";
      setResult(evaluation);
      setFeedBacks(feedback);

      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/createInterviewResult`,
        { applicantId, vacancyId, score: evaluation, feedback }
      );

      if (data?.success) {
        toast.success("Your Application has been submitted");
        navigate("/dashboard/candidate/applicant");
      }
    } catch (error) {
      toast.error("Failed to complete the interview.");
    }
  };

  const handleAskQuestion = async (userResponse = "") => {
    try {
      const responses = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/generateFollowUpQuestion`,
        {
          jobDescription,
          userResponse,
          chatHistory,
        }
      );
      const botMessage = responses.data.question.content;
      setChatHistory((prevHistory) => [
        ...prevHistory,
        { sender: "User", message: userResponse },
        { sender: "Interviewer", message: botMessage },
      ]);

      setAnswerCount((prevCount) => {
        const newCount = prevCount + 1;
        answerCountRef.current = newCount;
        return newCount;
      });

      setUserResponse("");
      speakText(botMessage);

      if (answerCountRef.current === 3) {
        completeInterview();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const generateInitialQuestion = async (
    jobTitle,
    jobType,
    experiencelevel,
    experienceUnit,
    skill,
    jobDescription,
    resume
  ) => {
    try {
      const responses = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/ai/getInitialQuestion`,
        {
          jobTitle,
          jobType,
          experiencelevel,
          experienceUnit,
          skill,
          jobDescription,
          resume,
        }
      );

      const botMessage = responses.data.question.content;
      setChatHistory([{ sender: "Interviewer", message: botMessage }]);
      speakText(botMessage);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getVacancy = async () => {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
      );

      if (data?.success) {
        const jobDetails = data.singleVacancy;
        setJobTitle(jobDetails.jobTitle);
        generateInitialQuestion(
          jobDetails.jobTitle,
          jobDetails.jobType,
          jobDetails.experiencelevel.value || 0,
          jobDetails.experiencelevel.unit,
          jobDetails.skills,
          DOMPurify.sanitize(jobDetails.jobDescription),
          resume
        );
      }
    } catch (error) {
      toast.error("Failed to load vacancy details");
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  return (
    <div className="flex flex-col h-screen bg-gray-100">
      <header className="bg-blue-500 text-white p-4">
        <h1 className="text-3xl">Interview - {jobTitle}</h1>
      </header>
      <main className="flex-1 overflow-y-auto p-4">
        {chatHistory.map((entry, index) => (
          <div
            key={index}
            className={`mb-2 p-2 rounded-lg ${
              entry.sender === "User"
                ? "bg-blue-100 self-end"
                : "bg-gray-300 self-start"
            }`}
          >
            <strong>{entry.sender}:</strong> {entry.message}
          </div>
        ))}
      </main>
      <footer className="p-4 bg-white border-t border-gray-300">
        <button
          onClick={startListening}
          className="p-2 bg-green-500 text-white rounded-lg"
        >
          {isListening ? "Listening..." : "Speak Your Answer"}
        </button>
      </footer>
    </div>
  );
};

export default VoiceInterview;
