import React, { useState, useEffect, useRef, useCallback } from "react";

const Voice = () => {
  const [paragraph, setParagraph] = useState("");
  const [sentences, setSentences] = useState([]);
  const [currentSentenceIndex, setCurrentSentenceIndex] = useState(0);
  const [displayedText, setDisplayedText] = useState("");

  const synthRef = useRef(null);
  const isSpeakingRef = useRef(false);

  const initializeSpeechSynthesis = useCallback(() => {
    if ("speechSynthesis" in window) {
      synthRef.current = window.speechSynthesis;
    } else {
      console.error("Speech synthesis not supported");
    }
  }, []);

  const speakSentence = useCallback((sentence) => {
    if (!synthRef.current || isSpeakingRef.current) return;

    const utterance = new SpeechSynthesisUtterance(sentence);
    utterance.rate = 1.2;
    utterance.pitch = 1;

    utterance.onstart = () => {
      isSpeakingRef.current = true;
    };

    utterance.onend = () => {
      isSpeakingRef.current = false;
      setCurrentSentenceIndex((prevIndex) => prevIndex + 1); // Move to the next sentence
    };

    synthRef.current.speak(utterance);
  }, []);
  const displaySentenceTypingEffect = useCallback((sentence) => {
    let currentIndex = 0;
    let currentTypedText = "";

    const typingInterval = setInterval(() => {
      if (currentIndex < sentence.length) {
        currentTypedText += sentence[currentIndex];
        setDisplayedText((prevText) => prevText + sentence[currentIndex]);

        currentIndex++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);

    return () => clearInterval(typingInterval);
  }, []);
  useEffect(() => {
    initializeSpeechSynthesis();

    const sampleParagraph =
      "Welcome to your virtual interview. Let's get started. I will ask you a series of questions. Please answer them thoughtfully.";
    setParagraph(sampleParagraph);

    // Split the paragraph into sentences
    const splitSentences = sampleParagraph.match(/[^\.!\?]+[\.!\?]+/g) || [];
    setSentences(splitSentences);

    // Display the entire paragraph initially
    setDisplayedText(paragraph);
  }, [initializeSpeechSynthesis]);

  useEffect(() => {
    // Display and speak the next sentence when the index updates
    if (currentSentenceIndex < sentences.length) {
      const currentSentence = sentences[currentSentenceIndex];
      displaySentenceTypingEffect(currentSentence); // Display sentence typing effect
      speakSentence(currentSentence); // Speak the sentence
    }
  }, [
    currentSentenceIndex,
    sentences,
    displaySentenceTypingEffect,
    speakSentence,
  ]);

  return (
    <div className="container mx-auto p-4">
      <div className="bg-white shadow-md rounded-lg p-6">
        <h1 className="text-2xl font-bold mb-4">Typing and Speaking</h1>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Original Paragraph:</h2>
          <p>{paragraph}</p>
        </div>

        <div className="mb-4">
          <h2 className="text-xl font-semibold mb-2">Displayed Text:</h2>
          <p className="border p-4 rounded-md bg-gray-100 text-black">
            {displayedText}
          </p>
        </div>
      </div>
    </div>
  );
};

export default Voice;
