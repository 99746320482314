import React from "react";
import heroAboutusBackground from "../../assets/about/heroAboutusBackground.png";
import dottedLineBelow from "../../assets/hero/dotted.png";

const HeroSection = () => {
  return (
    <div
      className="flex items-center justify-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${heroAboutusBackground})`,
      }}
    >
      <div
        className="bg-black bg-opacity-70 border border-white rounded-xl text-white text-center max-w-screen-lg"
        data-aos="flip-up"
      >
        <div>
          <div className="p-8 bg-[#182851]/50 flex flex-col items-center ">
            <div className="flex flex-wrap items-center justify-center ">
              <img
                src={dottedLineBelow}
                alt=""
                className="w-1/3 sm:w-1/5 px-2 sm:px-4"
              />

              <h1 className="text-white text-5xl">
                <span className="font-cinzel text-6xl">A</span>BOUT{" "}
                <span className="font-cinzel text-6xl">W</span>IZARDHIRE{" "}
              </h1>
              <img
                src={dottedLineBelow}
                alt=""
                className="w-1/3 sm:w-1/5 px-2 sm:px-4"
              />
            </div>
          </div>
          <div>
            <p className="text-white text-2xl py-6 px-4 text-center">
              At WizardHire, we're revolutionizing recruitment by blending
              AI-driven innovation with a deep commitment to fairness and
              security. What started as a serendipitous meeting between
              co-founders Francine Gutwilik and Adhiraj Dhungana in Winnipeg
              quickly evolved into a partnership fueled by the desire to create
              a platform that transcends borders, biases, and inefficiencies in
              the hiring process.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
