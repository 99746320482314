import React from "react";
import { NavLink } from "react-router-dom";

const EmployerSidebar = () => {
  return (
    <div className="w-64 bg-gray-800 shadow-md">
      <ul className="mt-6 flex flex-col">
        <NavLink
          to="/dashboard/employer"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/dashboard/employer/profile"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Profile
        </NavLink>
        <NavLink
          to="/dashboard/employer/createVacancy"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Create Vacancy
        </NavLink>
        <NavLink
          to="/dashboard/employer/vacancyInfromation"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Vacancy Information
        </NavLink>
        <NavLink
          to="/dashboard/employer/applicantInformation"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Applicant Information
        </NavLink>
        <NavLink
          to="/dashboard/employer/setting"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Setting
        </NavLink>
      </ul>
    </div>
  );
};

export default EmployerSidebar;
