import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";

const PasswordSetup = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");

  // Optional chaining with fallback
  const user = location?.state?.user || null;

  const handlePasswordSubmit = async () => {
    try {
      if (user?.email) {
        const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/v1/auth/setPassword`,
          {
            email: user.email,
            password,
          }
        );
        if (response?.data.success) {
          toast.success("Password set successfully");
          navigate("/login");
        }
      } else {
        toast.error("No user found");
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to set password");
    }
  };

  return (
    <>
      {user ? (
        <div className="min-h-screen flex items-center justify-center bg-gray-100">
          <div className="max-w-md w-full bg-white shadow-md rounded p-8 space-y-6">
            <h2 className="text-center text-2xl font-bold">Set Password</h2>
            <div>
              <p>Set a password to use email/password login in the future.</p>
              <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                className="w-full px-4 py-2 border rounded focus:outline-none focus:ring focus:border-blue-300"
                placeholder="Enter your password"
              />
            </div>
            <button
              onClick={handlePasswordSubmit}
              className="w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600"
            >
              Set Password
            </button>
          </div>
        </div>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default PasswordSetup;
