import React from "react";
import { NavLink } from "react-router-dom";

const AdminSidebar = () => {
  return (
    <div className="w-64 bg-gray-800 shadow-md">
      <ul className="mt-6 flex flex-col">
        <NavLink
          to="/dashboard/admin"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Dashboard
        </NavLink>
        <NavLink
          to="/dashboard/admin/profile"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Profile
        </NavLink>
        <NavLink
          to="/dashboard/admin/createEmployer"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Create Company
        </NavLink>
        <NavLink
          to="/dashboard/admin/employersInfromation"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Company Information
        </NavLink>
        <NavLink
          to="/dashboard/admin/setting"
          className="text-gray-300 hover:bg-gray-700 p-4 cursor-pointer text-center"
        >
          Setting
        </NavLink>
      </ul>
    </div>
  );
};

export default AdminSidebar;
