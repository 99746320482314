import React, { useState } from "react";
import Layout from "../components/Layout/Layout";
import Modal from "react-modal";
import whiteStar from "../assets/images/whiteStar.png";
import downPortal from "../assets/faq/downPortal.png";
import portal from "../assets/faq/portal.png";

const faqs = [
  {
    question: "What is WizardHire?",
    answer:
      "WizardHire is an AI-powered SaaS platform that revolutionizes recruitment by eliminating unconscious bias, ensuring that hiring decisions are fair, objective, and based purely on merit. Our innovative technology streamlines the hiring process for companies across industries.",
  },
  {
    question: "How does WizardHire prevent bias in hiring?",
    answer:
      "WizardHire leverages cutting-edge AI to evaluate candidates based on job-relevant data such as skills, experience, and performance, while removing personal biases related to gender, race, age, or background. Unlike traditional platforms, we focus solely on qualifications, ensuring a level playing field for all applicants.",
  },
  {
    question: "What industries does WizardHire serve?",
    answer:
      "WizardHire caters to a diverse range of industries, including tech, finance, sales, product management, healthcare, venture capital, and more. Our platform adapts to the unique hiring needs of each industry, offering tailored solutions.",
  },
  {
    question: "How much does WizardHire cost?",
    answer:
      "WizardHire operates on a transparent SaaS model. We charge a one-time $199 setup fee to customize the AI for your company's specific needs, plus $39 per month for each job posting. This ensures affordable access to advanced, bias-free recruitment tools.",
  },
  {
    question: "Is there a refund policy?",
    answer:
      "WizardHire does not offer refunds once the initial setup is in processing. The customization of our AI for your company begins immediately upon purchase, which requires significant resources. For any questions or concerns before setup, please reach out to our support team.",
  },
  {
    question: "Who are WizardHire’s co-founders?",
    answer:
      "WizardHire was co-founded by Francine Gutwilik, a seasoned HR expert with over 20 years of experience leading multiple successful startups, and Adhiraj Dhungana, a technical specialist with a background in physics and coding and AI development. Together, they bring a unique blend of HR expertise and AI-driven innovation to the platform.",
  },
  {
    question: "What level of candidates does WizardHire support?",
    answer:
      "WizardHire supports recruitment across all levels, from entry-level positions to C-suite executives. Our AI evaluates candidates based on your specific job descriptions, ensuring that every applicant is assessed fairly, regardless of experience level.",
  },
  {
    question: "Can WizardHire integrate with existing HR systems?",
    answer:
      "Yes, WizardHire is designed to integrate seamlessly with most HR systems. Our platform can be easily incorporated into your existing hiring workflows, ensuring a smooth, efficient recruitment process without the need for overhauls.",
  },
  {
    question: "What kind of data does WizardHire use in its process?",
    answer:
      "WizardHire focuses on analyzing job-relevant data, such as skills, work experience, education, and performance metrics. We intentionally avoid factors that can introduce bias, such as gender, race, or age, ensuring an objective and fair evaluation for all candidates.",
  },
  {
    question: "Is WizardHire suitable for startups?",
    answer:
      "Absolutely! WizardHire is designed to be scalable, making it an ideal solution for startups as well as businesses of any size. Whether you’re hiring your first team or expanding a large workforce, WizardHire offers customizable solutions to meet your needs.",
  },
  {
    question: "Where is WizardHire based?",
    answer:
      "WizardHire is proudly headquartered in Winnipeg, Manitoba, Canada. While our home is in Winnipeg, we serve clients across Canada and internationally, helping companies of all sizes build diverse, qualified teams.",
  },
  {
    question: "How does WizardHire handle security?",
    answer:
      "Security is a top priority at WizardHire. In today’s climate, with rising concerns about data breaches and scams, we’ve integrated industry-leading security protocols to protect your data and ensure the safety of every transaction. Our platform is built to safeguard sensitive information, and we continuously update our systems to stay ahead of potential threats.",
  },
  {
    question:
      "How is WizardHire different from other hiring platforms like LinkedIn or Indeed?",
    answer:
      "WizardHire sets itself apart by focusing on eliminating bias from the hiring process through AI. Unlike LinkedIn or Indeed, which rely on profile-based systems and network-driven hiring, we prioritize qualifications and skills to ensure every candidate is evaluated fairly. Our streamlined and cost-effective approach, with a $199 setup fee and just $39 per month per job opening, offers an affordable, specialized alternative to larger, more generic platforms.",
  },
  {
    question: "What makes WizardHire an innovative HR tool?",
    answer:
      "WizardHire is more than a job board—it’s a recruitment innovation. By using AI to refine and automate the candidate selection process, we provide companies with precise, bias-free hiring tools that save time and resources. Our personalized support and SaaS model make WizardHire an essential tool for modern HR practices.",
  },
  {
    question: "What kind of support does WizardHire offer?",
    answer:
      "WizardHire offers dedicated customer support to assist with any queries you may have during the setup process, job postings, or platform use. Whether you’re integrating with existing systems or fine-tuning your job postings, our support team is here to help.",
  },
  {
    question: "Does WizardHire require a long-term contract?",
    answer:
      "No long-term contract is required! WizardHire operates on a flexible, month-to-month subscription model. You can cancel or modify your job postings anytime, providing the flexibility to scale your hiring efforts as needed.",
  },
  {
    question: "Can candidates see job matches on WizardHire?",
    answer:
      "WizardHire does not match candidates with employers directly. We serve as a portal that allows candidates to apply for open positions listed by employers, ensuring a transparent application process without intermediary algorithms deciding job matches.",
  },
  {
    question: "How does WizardHire ensure transparency in the hiring process?",
    answer:
      "WizardHire emphasizes transparency in both pricing and functionality. Employers are charged only for active job postings, and candidates are evaluated purely on their qualifications. By eliminating the traditional profile-based approach, we provide an unbiased, clear view of each candidate’s potential.",
  },
];

Modal.setAppElement("#root"); // Set root element for accessibility

const FAQ = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);

  const openModal = (faq, index) => {
    setSelectedFaq(faq);
    setCurrentIndex(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedFaq(null);
    setCurrentIndex(0);
  };

  const handleNext = () => {
    if (currentIndex < faqs.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setSelectedFaq(faqs[currentIndex + 1]);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setSelectedFaq(faqs[currentIndex - 1]);
    }
  };

  return (
    <main className="bg-neutral-dark text-white dark:bg-neutral-light">
      <Layout title="WizardHire AI - FAQ">
        <section className="container relative max-w-screen-xl mx-auto pt-5">
          <div className="relative flex flex-row text-center items-center justify-center mb-10">
            <img src={whiteStar} alt="" className="w-8 mr-5" />
            <h1 className="text-white text-4xl uppercase">
              <span className="font-cinzel text-5xl">F</span>requently{" "}
              <span className="font-cinzel text-5xl">A</span>sked
              <span className="font-cinzel text-5xl"> Q</span>uestions
            </h1>
            <img src={whiteStar} alt="" className="w-8 ml-5" />
          </div>

          <ul className="space-y-4 text-xl text-center z-10 relative">
            {faqs.map((faq, index) => (
              <li
                key={index}
                className="cursor-pointer hover:text-gray-400"
                onClick={() => openModal(faq, index)}
              >
                {index + 1}. {faq.question}
              </li>
            ))}
          </ul>

          <div className="relative h-[400px] overflow-hidden mt-[-250px]">
            <img
              src={downPortal}
              alt="Down Portal"
              className="absolute bottom-0 left-1/2 transform -translate-x-1/2 w-[500px]"
            />
          </div>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className="container mx-auto flex flex-col border-black  justify-center items-center   z-30 h-[100%] w-[100%] relative"
            overlayClassName="fixed inset-0 bg-black z-20"
          >
            {/* <img
              src={portal}
              alt="Portal"
              className="absolute inset-0 w-full h-full object-cover z-10 p-10"
            /> */}
            <div className="relative flex-grow flex flex-col justify-center items-center z-20 p-20">
              <button
                onClick={closeModal}
                className="absolute top-20 right-2 text-white text-2xl font-bold"
              >
                &times;
              </button>
              {selectedFaq && (
                <div
                  className="flex flex-col justify-center items-center text-white p-[5rem]"
                  style={{
                    backgroundImage: `url(${portal})`,
                    backgroundSize: "100% 100%",
                    width: "600px",
                    minHeight: "600px",
                  }}
                >
                  <h2 className="text-3xl font-bold mb-4 text-center w-50%">
                    {selectedFaq.question}
                  </h2>
                  <p className="text-lg text-center w-50%">
                    {selectedFaq.answer}
                  </p>
                </div>
              )}
              <div className="flex justify-between w-full mt-4">
                <button
                  onClick={handlePrevious}
                  className="text-white"
                  disabled={currentIndex === 0}
                >
                  &lt; Previous
                </button>
                <button
                  onClick={handleNext}
                  className="text-white"
                  disabled={currentIndex === faqs.length - 1}
                >
                  Next &gt;
                </button>
              </div>
            </div>
          </Modal>
        </section>
      </Layout>
    </main>
  );
};

export default FAQ;
