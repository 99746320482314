import axios from "axios";
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { customStyles } from "../../styles/modalStyles";
const EmployerProfile = () => {
  const [employer, setEmployer] = useState();
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    companyName: "",
  });

  useEffect(() => {
    getemployerData();
  }, []);
  const getemployerData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/displayUser`
      );
      console.log(response);
      if (response.data.success) {
        setEmployer(response.data.user);
        setFormData({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          companyName: response.data.user.companyName,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/user/updateUser`,
        formData
      );
      console.log("Submit Response", response);
      if (response.data.success) {
        setEmployer(response.data.updatedUser);
        closeModal();
      }
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div className="b max-w-3xl mx-auto space-y-4">
      <h2 className="text-2xl font-semibold mb-4 border-b-2 border-gray-200 pb-2">
        Employer Profile
      </h2>

      {employer ? (
        <div className="flex flex-col space-y-2">
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">First Name:</strong>
            <span className="ml-2 text-gray-900">{employer.firstName}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Last Name:</strong>
            <span className="ml-2 text-gray-900">{employer.lastName}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Email:</strong>
            <span className="ml-2 text-gray-900">{employer.email}</span>
          </div>
          <div className="flex items-center">
            <strong className="w-1/3 text-gray-700">Company Name:</strong>
            <span className="ml-2 text-gray-900">{employer.companyName}</span>
          </div>
          <button
            onClick={openModal}
            className="mt-4 bg-blue-500 hover:bg-blue-700 text-white py-2 px-4 rounded"
          >
            Edit Profile
          </button>
        </div>
      ) : (
        <div>
          <p>Loading...</p>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Edit Profile"
        style={customStyles}
      >
        <h2 className="text-xl mb-4">Edit Profile</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label className="block mb-1" htmlFor="firstName">
              First Name:
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="lastName">
              Last Name:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              disabled
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="companyName">
              companyName:
            </label>
            <input
              type="text"
              id="companyName"
              name="companyName"
              value={formData.companyName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeModal}
              className="mr-2 px-4 py-2 border rounded bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Save
            </button>
          </div>
        </form>
      </Modal>
    </div>
  );
};

export default EmployerProfile;
