// UserRoleContext.js
import React, { createContext, useState, useContext } from "react";

const UserRoleContext = createContext();

export const UserRoleProvider = ({ children }) => {
  const [userRole, setUserRole] = useState(() => {
    const storedRole = localStorage.getItem("userRole");
    return storedRole ? JSON.parse(storedRole) : null;
  });

  const updateRole = (role) => {
    setUserRole(role);
    localStorage.setItem("userRole", JSON.stringify(role));
  };

  return (
    <UserRoleContext.Provider value={{ userRole, updateRole }}>
      {children}
    </UserRoleContext.Provider>
  );
};

export const useUserRole = () => useContext(UserRoleContext);
