import React from "react";

import Layout from "../components/Layout/Layout";
import Pricing from "../components/Services/PricingSection";
import ScrollSection from "../components/Services/ScrollSection";
import ServiceHeroSection from "../components/Services/ServiceHeroSection";
import ServiceWaitingFor from "../components/Services/ServiceWaitingFor";

const Services = () => {
  return (
    <>
      <Layout title="WizardHire AI - Services">
        <ServiceHeroSection />
        <ScrollSection />
        <Pricing />
        <ServiceWaitingFor />
      </Layout>
    </>
  );
};
export default Services;
