import React from "react";
import HeroSection from "../components/home/HeroSection/HeroSection";
import KeyFeatures from "../components/home/Features/Features";
import Works from "../components/home/Works/Works";
import FinalCta from "../components/home/FinalCta/FinalCta";
import Layout from "../components/Layout/Layout";
import Vision from "../components/home/Vision/Vision";

const Home = () => {
  return (
    <>
      <Layout title="WizardHire AI - RecruiterHome">
        <main className="bg-neutral-dark dark:bg-neutral-light">
          <HeroSection />
          <KeyFeatures />
          <Works />
          <Vision />
          <FinalCta />
        </main>
      </Layout>
    </>
  );
};

export default Home;
