import React from "react";
import pricingBackground from "../../assets/services/pricingBackground.png";
import messageIcon from "../../assets/services/messageIcon.png";
import { useUserRole } from "../../context/userRoleContext";

const employerPricingPlans = [
  {
    title: "CUSTOM AI SETUP",
    price: "$199.95",
    description: "Tailor-made AI designed to meet your hiring needs.",
    details: [
      "We build your AI based on proven HR models or collaborate with you to create a unique solution.",
      "One-time setup fee ensures you're ready to post jobs with ease.",
    ],
    buttonText: "GET STARTED",
    additionalInfo: "SETUP FEE",
  },
  {
    title: "JOB POSTING",
    price: "$39.95",
    description: "Post your job openings and start receiving top candidates.",
    details: [
      "Each job post lasts for 20 days and reaches qualified candidates.",
      "Easily manage all applications from the dashboard.",
    ],
    buttonText: "GET STARTED",
    additionalInfo: "PER POST (20 DAYS)",
  },
  {
    title: "CUSTOM & ENTERPRISE SOLUTIONS",
    icon: true,
    description: "Custom solutions for large-scale hiring needs.",
    details: [
      "Our team will work with you to create a custom solution that meets your unique hiring needs.",
    ],
    buttonText: "CONTACT US",
  },
];

const stepsCandidateData = [
  {
    title: "JOB POSTING",
    price: "Free!",
    description:
      "Apply for jobs effortlessly, receive instant feedback, and enhance your application process - all at zero cost.",
    details: [
      "WizardHire is completely free for applicants. Browse job listings, submit your application with ease, and benefit from Al-powered feedback to refine your approach. Whether you're a recent graduate or an experienced professional, our platform ensures you're supported throughout the application process - no fees, just opportunities. Welcome to the world of interactive job application!",
    ],
    buttonText: "GET STARTED",
  },
];

const Pricing = () => {
  const { userRole } = useUserRole();

  const steps =
    userRole === "recruiter" ? employerPricingPlans : stepsCandidateData;
  const isSinglePlan = steps.length === 1;

  return (
    <div
      className="flex items-center justify-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${pricingBackground})`,
      }}
    >
      <div
        className="container mx-auto flex flex-col my-10 py-10 px-4"
        data-aos="zoom-out-down"
      >
        <div className="text-center mb-10">
          <h1 className="text-white text-4xl sm:text-5xl font-semibold">
            <span className="font-cinzel text-5xl sm:text-6xl">P</span>RICING
          </h1>
        </div>

        <div className="w-full">
          <div
            className={`flex flex-wrap ${
              isSinglePlan ? "justify-center" : "justify-around"
            } gap-6`}
          >
            {steps.map((plan, index) => (
              <div
                key={index}
                className={`text-white ${
                  isSinglePlan
                    ? "w-full sm:w-3/4 md:w-2/3"
                    : "w-full sm:w-[300px]"
                } text-center`}
              >
                <button className="flex justify-center items-center text-center text-2xl bg-[#182851]/50 border border-white rounded-2xl w-full min-h-[60px]">
                  {plan.title}
                </button>
                <div className="bg-black/80 border border-white rounded-lg text-white text-lg mt-5 flex flex-col items-center min-h-[420px]">
                  <div className="bg-[#182851]/50 h-6 w-full rounded-t-lg"></div>
                  {plan.price && <p className="text-4xl mt-4">{plan.price}</p>}
                  {plan.icon && (
                    <div className="flex items-center justify-center mt-4">
                      <img
                        src={messageIcon}
                        alt="Message Icon"
                        className="h-12"
                      />
                    </div>
                  )}
                  {plan.additionalInfo && (
                    <p className="text-white mt-2">{plan.additionalInfo}</p>
                  )}
                  <div className="mt-4">
                    <button className="custom-button bg-white text-pure-black font-bold text-xl py-2 px-4 rounded-xl">
                      {plan.buttonText}
                    </button>
                  </div>
                  <p className="text-white mt-6">{plan.description}</p>
                  {plan.details.map((detail, detailIndex) => (
                    <p key={detailIndex} className="text-white mt-4">
                      {detail}
                    </p>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="text-white pt-8 text-center">
          <p className="text-lg font-medium">
            Need more details <span className="font-cinzel">?</span>
          </p>
          <p className="text-lg font-semibold">
            Please visit our{" "}
            <a href="/faq" className="underline">
              FAQ
            </a>{" "}
            section!
          </p>
        </div>
      </div>
    </div>
  );
};

export default Pricing;
