export const nameRegex = /^[a-zA-Z ]{3,50}$/;
export const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
export const passwordLengthRegex = /.{8,}/;
export const passwordUppercaseRegex = /[A-Z]/;
export const passwordLowercaseRegex = /[a-z]/;
export const passwordNumberRegex = /\d/;
export const passwordSpecialCharRegex = /[@$!%*?&]/;

export const validateName = (value, fieldName) => {
  if (!nameRegex.test(value)) {
    return `${fieldName} must be between 3 and 50 characters and contain only letters and spaces`;
  }
  return "";
};

export const validateEmail = (value) => {
  if (!emailRegex.test(value)) {
    return "Please enter a valid email address";
  }
  return "";
};

export const validatePassword = (value) => {
  const errors = [];
  if (!passwordLengthRegex.test(value)) {
    errors.push("Password must be at least 8 characters long");
  }
  if (!passwordUppercaseRegex.test(value)) {
    errors.push("Password must contain at least one uppercase letter");
  }
  if (!passwordLowercaseRegex.test(value)) {
    errors.push("Password must contain at least one lowercase letter");
  }
  if (!passwordNumberRegex.test(value)) {
    errors.push("Password must contain at least one number");
  }
  if (!passwordSpecialCharRegex.test(value)) {
    errors.push("Password must contain at least one special character");
  }
  return errors;
};
