import React from "react";
import straightLine from "../../assets/hero/straightLine.png";
import dottedLineBelow from "../../assets/hero/dottedLineBelow.png";
import dottedLineAbove from "../../assets/hero/dottedLineAbove.png";
import employerHeroBackground from "../../assets/services/employerHeroBackground.png";
import candidateHeroBackground from "../../assets/services/candidateHeroBackground.png";
import { useUserRole } from "../../context/userRoleContext";

const ServiceHeroSection = () => {
  const { userRole } = useUserRole();
  const heroSectionImage =
    userRole === "recruiter" ? employerHeroBackground : candidateHeroBackground;

  return (
    <main className="flex flex-col items-center justify-center text-3xl">
      <div
        className="relative w-full min-h-screen bg-cover bg-center"
        style={{
          backgroundImage: `url(${heroSectionImage})`,
        }}
      >
        <div className="absolute top-1/2 left-[2rem] transform -translate-y-1/2 w-2/3">
          <div className="text-white" data-aos="flip-left">
            {/* Straight Line */}
            <div
              className="w-1/3 h-2 bg-no-repeat bg-contain mb-4 ml-[18rem]"
              style={{
                backgroundImage: `url(${straightLine})`,
              }}
            />
            {/* Dotted Line Above */}
            <div
              className="w-2/3 h-2 bg-no-repeat bg-contain mb-4"
              style={{
                backgroundImage: `url(${dottedLineAbove})`,
              }}
            />
            {/* Conditional Heading */}
            {userRole === "recruiter" ? (
              <>
                <h2 className="tracking-widest px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">E</span>FFORTLESS{" "}
                  <span className="md:text-6xl font-cinzel">H</span>IRING <br />
                </h2>
                <h2 className="px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">P</span>OWERED{" "}
                  <span className="md:text-6xl font-cinzel">B</span>Y{" "}
                  <span className="md:text-6xl font-cinzel">A</span>I!{" "}
                </h2>
              </>
            ) : (
              <>
                <h2 className="tracking-widest px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">U</span>NLOCK{" "}
                  <span className="md:text-6xl font-cinzel">Y</span>OUR{" "}
                  <span className="md:text-6xl font-cinzel">D</span>REAM{" "}
                </h2>
                <h2 className="px-4 font-semibold relative md:text-5xl">
                  <span className="md:text-6xl font-cinzel">J</span>OB{" "}
                  <span className="md:text-6xl font-cinzel">W</span>ITH{" "}
                  <span className="md:text-6xl font-cinzel">W</span>IZARDHIRE !{" "}
                </h2>
              </>
            )}
            {/* Dotted Line Below */}
            <div
              className=" h-2 bg-no-repeat bg-contain my-4"
              style={{
                backgroundImage: `url(${dottedLineBelow})`,
              }}
            />
            <div className="mt-5">
              {userRole === "recruiter" ? (
                <p className="text-2xl">
                  Let our custom-built AI streamline your recruitment process,
                  ensuring you find the best talent faster than ever before.
                </p>
              ) : (
                <p className="text-2xl">
                  Take control of your job application with our AI-powered
                  platform. Apply seamlessly to job openings, receive real-time
                  feedback, and improve your chances of success – all for free.
                </p>
              )}
            </div>
            {/* Button */}
            <button className="px-12 py-2 border border-white rounded-2xl hover:bg-white hover:text-black mt-5">
              <span className="text-4xl font-cinzel">S</span>TART
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default ServiceHeroSection;
