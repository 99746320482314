import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DOMPurify from "dompurify";
import Header from "./Layout/Header/Header";
import Modal from "react-modal";
import { toast } from "react-toastify";
import { customStyles } from "../styles/modalStyles";

const SingleVacancy = () => {
  const params = useParams();
  const [vacancyInformation, setVacancyInformation] = useState(null);
  const [interviewMode, setInterviewMode] = useState("text"); // Default to text
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    vacancyId: "",
    resume: "",
  });

  const navigate = useNavigate();

  const getVacancy = async () => {
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_URL}/api/v1/vacancy/getSingleVacancy/${params.id}`
    );
    if (data.success === true) {
      setVacancyInformation(data.singleVacancy);
    }
  };
  const handleInterview = async (e) => {
    e.preventDefault();
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/applicant/createApplicant`,
        {
          firstName: formData.firstName,
          lastName: formData.lastName,
          email: formData.email,
          vacancyId: params.id,
          resume: formData.resume,
        }
      );

      if (data.success) {
        if (interviewMode === "text") {
          navigate(`/interview/text/${params.id}`, {
            state: {
              applicantId: data?.savedApplicant?._id,
              resume: data?.savedApplicant?.resume,
            },
          });
        } else if (interviewMode === "voice") {
          navigate(`/interview/voice/${params.id}`, {
            state: {
              applicantId: data?.savedApplicant?._id,
              resume: data?.savedApplicant?.resume,
            },
          });
        }
      }
    } catch (error) {
      toast.error("Some went wrong");
    }
  };
  const getcandidateData = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/user/displayUser`
      );
      if (response.data.success) {
        setFormData({
          firstName: response.data.user.firstName,
          lastName: response.data.user.lastName,
          email: response.data.user.email,
          resume: response.data.user.resume,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getVacancy();
  }, []);

  useEffect(() => {
    getcandidateData();
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const getFileName = (filePath) => {
    const fileName = filePath.split("/").pop(); // Get the last part of the URL
    return fileName.substring(fileName.indexOf("_") + 1); // Remove prefix
  };
  return (
    <>
      <div className="max-w-2xl mx-auto bg-white shadow-md rounded-md overflow-hidden">
        <Header />
        {vacancyInformation ? (
          <div className="p-6">
            <h2 className="text-2xl font-bold mb-2">
              {vacancyInformation.jobTitle}
            </h2>

            <p className="text-gray-600 mb-2">
              {vacancyInformation.companyName}
            </p>

            <p className="text-gray-600 mb-4">
              {vacancyInformation.experiencelevel.value === 0
                ? "Entry level"
                : `${vacancyInformation.experiencelevel.value} ${vacancyInformation.experiencelevel.unit}`}
            </p>
            <p className="text-gray-600 mb-4">{vacancyInformation.jobType}</p>
            <p className="text-gray-600 mb-4">
              {vacancyInformation.skills.join(", ")}
            </p>
            <div className="mb-4 text-start">
              <h3 className="text-lg font-semibold">Job Description</h3>
              <div
                className="job-description text-gray-800"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(vacancyInformation.jobDescription),
                }}
              ></div>
            </div>

            <button
              className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
              onClick={openModal}
            >
              Apply Now
            </button>
          </div>
        ) : (
          <div>Loading ...</div>
        )}
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Create Applicant"
        style={customStyles}
        // className="custom-modal-content custom-modal-overlay"
      >
        <h2 className="text-xl mb-4">Apply</h2>
        <form onSubmit={handleInterview}>
          <div>
            <label className="block mb-1" htmlFor="firstName">
              First Name:
            </label>
            <input
              type="text"
              id="firstName"
              name="firstName"
              value={formData.firstName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="lastName">
              Last Name:
            </label>
            <input
              type="text"
              id="lastName"
              name="lastName"
              value={formData.lastName}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="email">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div>
            <label className="block mb-1" htmlFor="resume">
              Resume:
            </label>
            <input
              type="text"
              id="resume"
              name="resume"
              value={getFileName(formData.resume)}
              onChange={handleChange}
              className="w-full px-3 py-2 border rounded"
              disabled
            />
            {formData?.resume && (
              <div className="flex">
                <a
                  href={formData.resume}
                  download
                  className="text-blue-500 underline"
                >
                  View Current Resume
                </a>
              </div>
            )}
          </div>
          <div className="mb-4">
            <label className="block mb-2 font-semibold">
              Choose Interview Mode:
            </label>
            <div className="flex items-center mb-2">
              <input
                type="radio"
                id="text"
                name="interviewMode"
                value="text"
                checked={interviewMode === "text"}
                onChange={() => setInterviewMode("text")}
                className="mr-2"
              />
              <label htmlFor="text" className="mr-4">
                Text Interview
              </label>

              <input
                type="radio"
                id="voice"
                name="interviewMode"
                value="voice"
                checked={interviewMode === "voice"}
                onChange={() => setInterviewMode("voice")}
                className="mr-2"
              />
              <label htmlFor="voice">Voice Interview</label>
            </div>
          </div>
          <div className="flex justify-end">
            <button
              type="button"
              onClick={closeModal}
              className="mr-2 px-4 py-2 border rounded bg-gray-300"
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              Take Interview
            </button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SingleVacancy;
