import React from "react";
import CandidateSidebar from "../../components/candidate/CandidateSidebar";
import Header from "../../components/Layout/Header/Header";
import CandidateProfile from "../../components/candidate/CandidateProfile";

const CandidateProfilePage = () => {
  return (
    <div className="flex min-h-screen">
      <CandidateSidebar />
      <div className="flex flex-1 flex-col ">
        <Header />

        <div className="flex-1 p-6 bg-gray-100">
          <div className="bg-white p-6 ronded-lg shadow-md text-center">
            <CandidateProfile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateProfilePage;
