import React, { useState, useEffect } from "react";
import recruiterKeyFeatures from "../../../assets/features/recruiterKeyFeatures.png";
import applicantKeyFeatures from "../../../assets/features/applicantKeyFeatures.png";

import LeftSelectionSection from "../../../assets/features/LeftSelectionSection.png";
import RightSelectionSection from "../../../assets/features/RightSelectionSection.png";
import MiddleSelectionSection from "../../../assets/features/MiddleSelectionSection.png";
import Buttonleft_Selection_Section from "../../../assets/features/Buttonleft_Selection_Section.png";
import ButtonMiddle_Secletion_Section from "../../../assets/features/ButtonMiddle_Secletion_Section.png";
import ButtonRight_Selection_Section from "../../../assets/features/ButtonRight_Selection_Section.png";
import AI_Driven_Glow from "../../../assets/features/AI_Driven_Glow .png";
import AI_Driven from "../../../assets/features/AI_Driven.png";
import Customizable_Solutions from "../../../assets/features/Customizable_Solutions.png";
import Customizable_Solutions_Glow from "../../../assets/features/Customizable_Solutions_Glow.png";
import Interactive_PreInterviews from "../../../assets/features/Interactive_PreInterviews.png";
import Interactive_PreInterviewsGlow from "../../../assets/features/Interactive_PreInterviewsGlow .png";
import Resume_ConsistencyMonitoring from "../../../assets/features/Resume_ConsistencyMonitoring.png";
import Resume_ConsistencyMonitoring_Glow from "../../../assets/features/Resume_ConsistencyMonitoring_Glow.png";
import Seamless_JobPostingRedirection from "../../../assets/features/Seamless_JobPostingRedirection.png";
import Seamless_JobPostingGlow from "../../../assets/features/Seamless_JobPostingGlow.png";
import Streamlined_DecisionMaking from "../../../assets/features/Streamlined_DecisionMaking.png";
import Streamlined_DecisionMakingGlow from "../../../assets/features/Streamlined_DecisionMakingGlow.png";
import { useUserRole } from "../../../context/userRoleContext";

const Features = () => {
  const [currentBoxIndex, setCurrentBoxIndex] = useState(0);
  const [hoverIndex, setHoverIndex] = useState(null); // State to track hover

  const featureEmployerSection = [
    {
      id: 1,
      title: "Seamless Job Posting and Redirection",
      icon: Seamless_JobPostingRedirection,
      description:
        "Easily list job openings on your preferred platforms, and drive all applicants to WizardHire for a standardized and streamlined process",
      sectionImage: LeftSelectionSection,
      iconHover: Seamless_JobPostingGlow,
    },
    {
      id: 2,
      title: "AI-Driven Screening",
      icon: AI_Driven,
      description:
        "Save time by letting our AI handle initial screenings. The AI reviews applicants based on your specific criteria, ensuring precise and unbiased assessments",
      sectionImage: MiddleSelectionSection,
      iconHover: AI_Driven_Glow,
    },
    {
      id: 3,
      title: "Interactive Pre-Interviews",
      icon: Interactive_PreInterviews,
      description:
        "Our AI conducts customized pre-interviews that ask role-specific questions, providing real-time insights into candidate responses and suitability.",
      sectionImage: RightSelectionSection,
      iconHover: Interactive_PreInterviewsGlow,
    },

    {
      id: 4,
      title: "Resume Consistency Monitoring",
      icon: Resume_ConsistencyMonitoring,
      sectionImage: Buttonleft_Selection_Section,
      iconHover: Resume_ConsistencyMonitoring_Glow,
      description:
        "Ensure transparency and integrity with real-time resume consistency checks. Our AI detects discrepancies in an applicant's resume across different job applications and flags significant changes.",
    },
    {
      id: 5,
      title: "Streamlined Decision-Making",
      icon: Streamlined_DecisionMaking,
      sectionImage: ButtonMiddle_Secletion_Section,
      iconHover: Streamlined_DecisionMakingGlow,
      description:
        "Receive candidates who have undergone multiple AI reviews for accuracy. Candidates flagged for human review come with all insights ready for efficient decision-making.",
    },
    {
      id: 6,
      title: "Customizable Solutions",
      icon: Customizable_Solutions,
      sectionImage: ButtonRight_Selection_Section,
      iconHover: Customizable_Solutions_Glow,
      description:
        "Use our proven HR models or work with our team to build an AI that mirrors your company’s unique hiring process. WizardHire adapts to your needs, whether you're hiring for a small team or managing large-scale recruitment.",
    },
  ];
  const featureCandidateSection = [
    {
      id: 1,
      title: "Seamless Application Process",
      icon: Seamless_JobPostingRedirection,
      description:
        "Submit your resume, cover letter, and details all in one place, with easy redirection from job postings on popular job boards.",
      sectionImage: LeftSelectionSection,
      iconHover: Seamless_JobPostingGlow,
    },
    {
      id: 2,
      title: "AI-Driven Screening",
      icon: AI_Driven,
      description:
        "Experience a thorough review of your application, with the AI assessing your qualifications based on the employer’s specific needs.",
      sectionImage: MiddleSelectionSection,
      iconHover: AI_Driven_Glow,
    },
    {
      id: 3,
      title: "Interactive Application Experience",
      icon: Interactive_PreInterviews,
      description:
        "Engage in pre-interviews with the AI that mimic real conversations, allowing you to showcase your skills and suitability for the role in real-time.",
      sectionImage: RightSelectionSection,
      iconHover: Interactive_PreInterviewsGlow,
    },

    {
      id: 4,
      title: "Resume Consistency Monitoring",
      icon: Resume_ConsistencyMonitoring,
      sectionImage: Buttonleft_Selection_Section,
      iconHover: Resume_ConsistencyMonitoring_Glow,
      description:
        "WizardHire flags any discrepancies between job applications, but don’t worry - if any are found, you'll be prompted to clarify them.",
    },
    {
      id: 5,
      title: "Instant Feedback",
      icon: Streamlined_DecisionMaking,
      sectionImage: ButtonMiddle_Secletion_Section,
      iconHover: Streamlined_DecisionMakingGlow,
      description:
        "Get immediate feedback on your application status. Whether moving forward or not, you receive valuable insights to help you in your next steps",
    },
    {
      id: 6,
      title: "Fair Opportunity",
      icon: Customizable_Solutions,
      sectionImage: ButtonRight_Selection_Section,
      iconHover: Customizable_Solutions_Glow,
      description:
        "Each applicant is given a chance to explain potential resume discrepancies, ensuring fairness in the screening process.",
    },
  ];
  const { userRole } = useUserRole();
  const featureSection =
    userRole === "recruiter" ? featureEmployerSection : featureCandidateSection;
  const keyFeatureBackground =
    userRole === "recruiter" ? recruiterKeyFeatures : applicantKeyFeatures;

  // Change content every 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentBoxIndex(
        (prevIndex) => (prevIndex + 1) % featureSection.length
      );
    }, 4000);

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="flex flex-col pt-10  bg-cover bg-center"
      style={{ backgroundImage: `url(${keyFeatureBackground})` }}
    >
      <div
        className="container mx-auto max-w-screen-lg my-6"
        data-aos="flip-left"
      >
        <div className="flex flex-row text-center items-center justify-center mb-10 ">
          <h1 className="font-bold text-white text-5xl">
            <span className="font-cinzel text-6xl">K</span>EY{" "}
            <span className="font-cinzel text-6xl">F</span>EATURES
          </h1>
        </div>
        {/* Row 1: Boxes 1, 2, 3 */}
        <div className="flex border-t border-b flex-wrap">
          {featureSection.slice(0, 3).map((item, index) => (
            <div
              key={item.id}
              className={`w-1/3 h-64 relative text-white cursor-pointer flex items-center justify-center bg-[#1E140D]/60 border  border-white`}
              onClick={() => setCurrentBoxIndex(index)}
              onMouseEnter={() => setHoverIndex(index)}
              onMouseLeave={() => setHoverIndex(null)} // Clear hover state
              style={{
                marginBottom: "-30px", // Adjust to create overlap
                zIndex: 1,
              }}
            >
              <div className="text-center">
                <img
                  src={
                    hoverIndex === index || currentBoxIndex === index
                      ? item.iconHover // Show hover icon when hovered or selected
                      : item.icon
                  }
                  alt={item.title}
                  className="mx-auto mb-2 w-[20%] h-auto"
                />
                <h3 className="text-xl">{item.title}</h3>
              </div>
            </div>
          ))}
        </div>

        {/* Middle Content (Background image changes) */}
        <div
          className="flex-grow text-white md:p-20 relative flex justify-center items-center w-full min-h-[300px]"
          style={{
            backgroundImage: `url(${featureSection[currentBoxIndex].sectionImage})`,
            backgroundSize: "100% 100%",
            backgroundPosition: "center",
            zIndex: 2,
          }}
        >
          <p className="md:w-2/3 text-center text-2xl">
            {featureSection[currentBoxIndex].description}
          </p>
        </div>

        {/* Row 2: Boxes 4, 5, 6 */}
        <div className="flex flex-wrap border-t border-b">
          {featureSection.slice(3).map((item, index) => (
            <div
              key={item.id}
              className={`w-1/3 h-64 relative text-white cursor-pointer flex items-center justify-center bg-[#1E140D]/60 border  border-white`}
              onClick={() => setCurrentBoxIndex(index + 3)}
              onMouseEnter={() => setHoverIndex(index + 3)}
              onMouseLeave={() => setHoverIndex(null)} // Clear hover state
              style={{
                marginTop: "-30px", // Adjust to create overlap
                zIndex: 1,
              }}
            >
              <div className="text-center">
                <img
                  src={
                    hoverIndex === index + 3 || currentBoxIndex === index + 3
                      ? item.iconHover // Show hover icon when hovered or selected
                      : item.icon
                  }
                  alt={item.title}
                  className="mx-auto mb-2 w-[20%]"
                />
                <h3 className="text-xl">{item.title}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Features;
