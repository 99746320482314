import React from "react";
import whyDifferentBackground from "../../assets/about/whyDifferentBackground.png";

const WhyWizardHireSection = () => {
  return (
    <div
      className="flex items-center justify-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${whyDifferentBackground})`,
      }}
    >
      <div
        className="container mx-auto px-4 md:px-8 lg:px-16 mt-10 text-black"
        data-aos="fade-down-left"
      >
        {/* Main Title */}
        <h2 className="text-3xl md:text-5xl font-bold mb-8 text-center ">
          <span className="font-cinzel">W</span>hy
          <span className="font-cinzel"> W</span>izardHire is
          <span className="font-cinzel"> D</span>ifferent:
        </h2>

        {/* Feature Section */}
        <div className="space-y-6 text-lg md:text-2xl">
          {/* Feature 1 */}
          <div>
            <span className="font-semibold">Bias-Free Hiring: </span>
            Traditional job boards often focus on networking or profiles, but
            WizardHire’s AI eliminates unconscious bias, providing a fairer and
            more objective evaluation of candidates.
          </div>

          {/* Feature 2 */}
          <div>
            <span className="font-semibold">
              Streamlined & Cost-Effective:{" "}
            </span>
            For a transparent setup fee of $199 and just $39 per job posting
            valid for 20 days, WizardHire offers a powerful SaaS solution that’s
            not only efficient but also cost-effective. No hidden fees, just
            seamless recruitment.
          </div>

          {/* Feature 3 */}
          <div>
            <span className="font-semibold">HR Innovation Focus: </span>
            Unlike general job boards, WizardHire is designed to disrupt and
            elevate HR practices with technology and personalized support,
            helping companies optimize their talent acquisition process.
          </div>
        </div>

        {/* Highlighted Text Box */}
        <div className="w-full p-6 md:p-10 mt-8 bg-black bg-opacity-50 border rounded-lg text-center text-white">
          <p className="text-lg md:text-2xl">
            From the first steps of developing this platform, we’ve been
            dedicated to creating something truly unique—an affordable,
            AI-driven recruiting tool that cuts through the noise and makes
            hiring easier, faster, and fairer for all.
          </p>
          <p className="text-lg md:text-2xl mt-4">
            Join us in transforming the future of work, one hire at a
            time—because at WizardHire, we believe that great talent knows no
            boundaries.
          </p>
        </div>
      </div>
    </div>
  );
};

export default WhyWizardHireSection;
