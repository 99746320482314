import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

const EmployerCandidateInterviewResult = () => {
  const params = useParams();

  const [result, setResult] = useState([]);
  const [filter, setFilter] = useState("all"); // State for filter option

  const getResult = async () => {
    const { data } = await axios.post(
      `${process.env.REACT_APP_API_URL}/api/v1/ai/displayVacancyResult/${params.id}`
    );

    if (data.success === true) {
      setResult(data?.results);
    }
  };

  const updateStatus = async (applicantId, newStatus) => {
    try {
      const { data } = await axios.put(
        `${process.env.REACT_APP_API_URL}/api/v1/applicant/updateApplicantStatus/${applicantId}`,
        { status: newStatus }
      );
      if (data.success) {
        // Update the result state to reflect the new status
        setResult((prev) =>
          prev.map((item) =>
            item.applicantId._id === applicantId
              ? {
                  ...item,
                  applicantId: { ...item.applicantId, status: newStatus },
                }
              : item
          )
        );
        toast("Status updated successfully!");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      toast("Failed to update status.");
    }
  };

  useEffect(() => {
    getResult();
  }, []);

  // Filtering the applicants based on the selected filter option
  const filteredResults = result.filter((res) => {
    if (filter === "all") return true;
    return res?.applicantId?.status === filter;
  });

  return (
    <div>
      <div className="mb-5">
        <label className="mr-2">Filter by Status: </label>
        <select
          value={filter}
          onChange={(e) => setFilter(e.target.value)}
          className="border p-2 rounded"
        >
          <option value="all">All</option>
          <option value="pending">Pending</option>
          <option value="selected">Selected</option>
          <option value="rejected">Rejected</option>
        </select>
      </div>

      {filteredResults?.length > 0 ? (
        filteredResults.map((res) => (
          <div className="p-6 border my-5" key={res._id}>
            <p className="text-2xl mb-2">
              <strong>First Name:</strong> {res?.applicantId?.firstName}
            </p>
            <p className="text-2xl mb-2">
              <strong>Last Name:</strong> {res?.applicantId?.lastName}
            </p>
            <p className="text-2xl mb-2">
              <strong>Email:</strong> {res?.applicantId?.email}
            </p>
            <p className="text-2xl mb-2">
              <strong>Resume:</strong> {res?.applicantId?.resume}
            </p>
            <div className="text-gray-600 mb-2">
              <strong>Status: </strong>
              <select
                value={res?.applicantId?.status}
                onChange={(e) =>
                  updateStatus(res.applicantId._id, e.target.value)
                }
                className="border rounded p-2"
              >
                <option value="pending">Pending</option>
                <option value="selected">Selected</option>
                <option value="rejected">Rejected</option>
              </select>
            </div>
            <p className="text-gray-600 mb-2">
              <strong>User Score:</strong> {res?.score}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>User Id:</strong> {res?.userId}
            </p>
            <p className="text-gray-600 mb-2">
              <strong>Feedback:</strong> {res?.feedback}
            </p>
          </div>
        ))
      ) : (
        <div>No more Data</div>
      )}
    </div>
  );
};

export default EmployerCandidateInterviewResult;
