import React from "react";
import { useUserRole } from "../../context/userRoleContext";
import whyUsBackground from "../../assets/services/whyUsBackground.png";

const candidateBenefits = [
  {
    title: "Equal Opportunity and Fairness",
    description:
      "The AI ensures that all candidates are evaluated based on the same criteria, eliminating unconscious bias and focusing purely on qualifications and experience.",
  },
  {
    title: "Constructive Feedback",
    description:
      "If you're not selected for a position, WizardHire offers personalized feedback to help improve your future applications.",
  },
  {
    title: "Enhanced Applicant Experience",
    description:
      "Our interactive AI application process makes applying engaging and offers you real-time insights on where you stand.",
  },
  {
    title: "Integrity in the Process",
    description:
      "Resume consistency checks ensure that honest candidates are rewarded. By clarifying any discrepancies, you prevent misunderstandings that could affect your application.",
  },
  {
    title: "Privacy-First Approach",
    description:
      "Your data is handled with care. Only the necessary information is shared with potential employers, and your privacy is always prioritized.",
  },
  {
    title: "Efficiency",
    description:
      "WizardHire's AI quickly screens applications, helping you know where you stand faster than traditional methods.",
  },
  {
    title: "Level Playing Field",
    description:
      "Whether applying to a large corporation or a small company, WizardHire gives everyone the same opportunity to succeed by presenting applications fairly and accurately.",
  },
];

const employerBenefits = [
  {
    title: "Time and Resource Savings",
    description:
      "Automate initial screenings to focus on top candidates, reducing your HR team's workload.",
  },
  {
    title: "Scalable Hiring Process",
    description:
      "Handle high volumes of applicants seamlessly, no matter the size of your company or the number of positions you’re hiring for.",
  },
  {
    title: "Increased Hiring Accuracy",
    description:
      "Benefit from unbiased AI screening and flagging of potential red flags, allowing you to focus on the most qualified candidates.",
  },
  {
    title: "Enhanced Credibility and Trust",
    description:
      "By incorporating AI-driven resume checks and consistency monitoring, you build a hiring process rooted in transparency, fairness, and integrity.",
  },
  {
    title: "Fraud Prevention",
    description:
      "The built-in resume fraud detection system significantly reduces the risk of hiring candidates with misrepresented qualifications or experience.",
  },
  {
    title: "Customizable and Flexible Solutions",
    description:
      "Tailor WizardHire to your company’s hiring needs and maintain full control over which candidates get flagged and what discrepancies matter most.",
  },
  {
    title: "Constructive Applicant Feedback",
    description:
      "Candidates who don’t make it receive constructive feedback, leaving them with a positive impression of your company and encouraging them to apply again in the future.",
  },
];

const ScrollSection = () => {
  const { userRole } = useUserRole();

  const benefits =
    userRole === "recruiter" ? employerBenefits : candidateBenefits;

  return (
    <div
      className="flex items-center justify-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${whyUsBackground})`,
      }}
    >
      <div
        className="bg-black bg-opacity-70  rounded-lg text-white max-w-screen-xl p-10 m-10"
        data-aos="zoom-in"
      >
        <div>
          <h2 className="text-5xl font-bold mb-4 text-center">
            Why Use WizardHire?
          </h2>

          {/* List Items */}
          <ul className="list-disc mb-4 text-2xl">
            {benefits.map((benefit, index) => (
              <>
                <h3 className="font-bold mb-1">{benefit.title}:</h3>

                <li key={index} className="mb-4 ml-8">
                  <p>{benefit.description}</p>
                </li>
              </>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default ScrollSection;
