import React from "react";
import AdminSidebar from "../../components/admin/AdminSidebar";
import AdminEmployersInformation from "../../components/admin/AdminEmployersInformation";
import Header from "../../components/Layout/Header/Header";

const AdminEmployersInformationPage = () => {
  return (
    <>
      <div className="flex h-screen">
        <AdminSidebar />
        <div className="flex-1 flex flex-col">
          <Header />
          <AdminEmployersInformation />
        </div>
      </div>
    </>
  );
};

export default AdminEmployersInformationPage;
