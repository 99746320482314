import React from "react";
import EmployerSidebar from "../../components/employer/EmployerSidebar";
import Header from "../../components/Layout/Header/Header";
import EmployerVacancyInformation from "../../components/employer/EmployerVacancyInformation";

const EmployerVacancyInformationPage = () => {
  return (
    <div className="flex min-h-screen">
      <EmployerSidebar />
      <div className="flex flex-1 flex-col ">
        <Header />
        <div className="flex-1 p-6 bg-gray-100">
          <div className="bg-white p-6 ronded-lg shadow-md text-center">
            <EmployerVacancyInformation />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployerVacancyInformationPage;
