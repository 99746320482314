import React from "react";
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
import { Helmet } from "react-helmet";

const Layout = ({
  children,
  title = "WizardHire AI",
  description = "",
  keyword = "",
  author = "",
}) => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="description" content={description} />
        <meta name="keywords" content={keyword} />
        <meta name="author" content={author} />
        <title>{title}</title>
      </Helmet>
      <Header />

      {children}
      <Footer />
    </div>
  );
};

export default Layout;
