import { useEffect, useState } from "react";
import { useAuth } from "../context/auth";
import axios from "axios";
import { Outlet } from "react-router-dom";
import Spinner from "../Spinner";
// import { Loading } from "../Spinner";

const AdminRoute = () => {
  const [success, setSuccess] = useState(false);
  const [auth] = useAuth();
  useEffect(() => {
    const authCheck = async () => {
      try {
        const respond = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/auth/auth-admin`
        );
        if (respond.data.success) {
          setSuccess(true);
        }
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setSuccess(false);
        } else {
          console.error("An unexpected error occurred:", error);
        }
      }
    };
    if (auth?.token) authCheck();
  }, [auth?.token]);
  return success ? <Outlet /> : <Spinner path="" />;
};

export default AdminRoute;
