import axios from "axios";
import React, { useEffect, useState } from "react";

const Applicant = () => {
  const [result, setResult] = useState([]);

  const getResult = async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/applicant/displayApplicant`
      );
      if (data?.success) {
        console.log(data);
        setResult(data?.applicant);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getResult();
  }, []);

  return (
    <div>
      {result.length > 0 ? (
        result.map((res) => (
          <div className="p-6 border my-5" key={res._id}>
            <p className="text-2xl mb-2">
              <strong>First Name:</strong>
              {res.firstName}
            </p>
            <p className="text-2xl mb-2">
              <strong>Last Name:</strong>
              {res.lastName}
            </p>
            {res.vacancyId && (
              <>
                <p className="text-2xl mb-2">
                  <strong>Company Name:</strong>
                  {res.vacancyId.companyName}
                </p>
                <p className="text-2xl mb-2">
                  <strong>Job Title:</strong>
                  {res.vacancyId.jobTitle}
                </p>

                <p className="text-2xl mb-2">
                  <strong>Job Type:</strong>
                  {res.vacancyId.jobType}
                </p>
              </>
            )}
            <p className="text-2xl mb-2">
              <strong>status:</strong>
              {res?.status}
            </p>
          </div>
        ))
      ) : (
        <div>No more Data</div>
      )}
    </div>
  );
};

export default Applicant;
