import React from "react";
import recruiterWaitingBackground from "../../../assets/waitingFor/recruiterWaitingBackground.png";
import applicantWaitingBackground from "../../../assets/waitingFor/applicantWaitingBackground.png";
import { useUserRole } from "../../../context/userRoleContext";

const FinalCta = () => {
  const { userRole } = useUserRole();
  const waitingBackground =
    userRole === "recruiter"
      ? recruiterWaitingBackground
      : applicantWaitingBackground;

  return (
    <div
      className="flex items-center justify-center bg-cover bg-center min-h-screen"
      style={{
        backgroundImage: `url(${waitingBackground})`,
      }}
    >
      <div
        className="bg-black bg-opacity-70 p-10 rounded-lg text-white text-center max-w-screen-md"
        data-aos="fade-zoom-in"
      >
        <div className="mb-8">
          <h1 className="text-white text-5xl">
            <span className="font-cinzel text-6xl">S</span>O{" "}
            <span className="font-cinzel text-6xl">W</span>HAT{" "}
            <span className="font-cinzel text-6xl">A</span>RE
            <br />
            <span className="font-cinzel text-6xl">Y</span>OU{" "}
            <span className="font-cinzel text-6xl">W</span>AITING{" "}
            <span className="font-cinzel text-6xl">F</span>OR ?
          </h1>
        </div>
        <button className="px-12 py-2 border border-white rounded-2xl hover:bg-white hover:text-black text-3xl">
          <span className="text-4xl font-cinzel">S</span>TART{" "}
          <span className="text-4xl font-cinzel">N</span>OW
        </button>
      </div>
    </div>
  );
};

export default FinalCta;
