import React from "react";
import { useAuth } from "../context/auth";

const UserProfile = () => {
  const [auth] = useAuth();
  return (
    <div>
      {console.log(auth)}
      <h1>{auth?.user?.id}</h1>
      <h1 className="text-2xl">FirstName: {auth?.user?.firstName}</h1>
      <h1 className="text-2xl">LastName: {auth?.user?.lastName}</h1>
      <h1 className="text-2xl">Email: {auth?.user?.email}</h1>
      
    </div>
  );
};

export default UserProfile;
