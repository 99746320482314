import Interview from "./components/Interview";
import { Routes, Route } from "react-router-dom";
import { GoogleOAuthProvider } from "@react-oauth/google";
import SingleVacancy from "./components/SingleVacancy";
import Login from "./pages/auth/login";
import Signup from "./pages/auth/Signup";
import AdminRoute from "./routes/AdminRoute";
import CandidateRoute from "./routes/CandidateRoute";
import EmployeeRoute from "./routes/EmployeeRoute";
import Home from "./pages/Home";
import AdminDashboard from "./pages/admin/AdminDashboardPage";
import CandidateDashboardPage from "./pages/candidate/CandidateDashboardPage";
import CandidateProfilePage from "./pages/candidate/CandidateProfilePage";
import CandidateSettingPage from "./pages/candidate/CandidateSettingPage";
import EmployerDashboardPage from "./pages/employer/EmployerDashboardPage";
import EmployerCreateVacancyPage from "./pages/employer/EmployerCreateVacancyPage";
import EmployerProfilePage from "./pages/employer/EmployerProfilePage";
import EmployerVacancyInformationPage from "./pages/employer/EmployerVacancyInformationPage";
import EmployerSettingPage from "./pages/employer/EmployerSettingPage";
import AdminProfilePage from "./pages/admin/AdminProfilePage";
import AdminCreateCompanyPage from "./pages/admin/AdminCreateCompanyPage";
import AdminSettingPage from "./pages/admin/AdminSettingPage";
import AdminEmployersInformationPage from "./pages/admin/AdminEmployersInformationPage";
import EmployerSingleVacancyInformationPage from "./pages/employer/EmployerSingleVacancyInformationPage";
import EmployerEditSingleVacancyPage from "./pages/employer/EmployerEditSingleVacancyPage";
import EmployerShowInterviewResult from "./pages/employer/EmployerShowInterviewResult";
import CandidateApplicantPage from "./pages/candidate/CandidateApplicantPage";
import PasswordSetup from "./pages/auth/PasswordSetup";
import EmployerApplicantResultInformation from "./pages/employer/EmployerApplicantResultInformation";
import EmployerApplicantInformationPage from "./pages/employer/EmployerApplicantInformationPage";
import SelectionPage from "./pages/Selection/SelectionPage";
import Services from "./pages/Services";
import About from "./pages/About";
import Faq from "./pages/Faq";
import VoiceInterview from "./components/VoiceInterview";

import Modal from "react-modal";
import Voice from "./components/Voice";

// Set the app element for the modal (helps with accessibility)
Modal.setAppElement("#root");

function App() {
  const GoogleAuthWrapper = () => {
    return (
      <GoogleOAuthProvider clientId="997274802886-io8p730j493nu7r5nl2j7tuers2lvkef.apps.googleusercontent.com">
        <Login />
      </GoogleOAuthProvider>
    );
  };
  return (
    <>
      <Routes>
        <Route path="/" element={<SelectionPage />} />
        <Route path="/home" element={<Home />} />
        <Route path="/voice" element={<Voice />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/service" element={<Services />} />
        <Route path="/faq" element={<Faq />} />
        <Route path="/login" element={<GoogleAuthWrapper />} />
        <Route path="/set-password" element={<PasswordSetup />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/interview/text/:id" element={<Interview />} />
        <Route path="/interview/voice/:id" element={<VoiceInterview />} />
        <Route path="/dashboard" element={<AdminRoute />}>
          <Route path="admin" element={<AdminDashboard />} />
          <Route path="admin/profile" element={<AdminProfilePage />} />
          <Route
            path="admin/createEmployer"
            element={<AdminCreateCompanyPage />}
          />
          <Route
            path="admin/employersInfromation"
            element={<AdminEmployersInformationPage />}
          />
          <Route path="admin/setting" element={<AdminSettingPage />} />
        </Route>
        <Route path="/dashboard" element={<CandidateRoute />}>
          <Route path="vacancyInfo/:id" element={<SingleVacancy />} />
          <Route path="candidate" element={<CandidateDashboardPage />} />
          <Route path="candidate/profile" element={<CandidateProfilePage />} />
          <Route
            path="candidate/applicant"
            element={<CandidateApplicantPage />}
          />
          <Route path="candidate/setting" element={<CandidateSettingPage />} />
        </Route>
        <Route path="/dashboard" element={<EmployeeRoute />}>
          <Route path="employer" element={<EmployerDashboardPage />} />
          <Route path="employer/profile" element={<EmployerProfilePage />} />
          <Route
            path="employer/createVacancy"
            element={<EmployerCreateVacancyPage />}
          />
          <Route
            path="employer/vacancyInfromation"
            element={<EmployerVacancyInformationPage />}
          />
          <Route
            path="employer/applicantInformation"
            element={<EmployerApplicantResultInformation />}
          />
          <Route
            path="employer/vacancyInfromation/:id"
            element={<EmployerSingleVacancyInformationPage />}
          />
          <Route
            path="employer/applicantInfromation/:id"
            element={<EmployerApplicantInformationPage />}
          />
          <Route
            path="employer/editVacancyInfromation/:id"
            element={<EmployerEditSingleVacancyPage />}
          />
          <Route
            path="employer/showResult/:id"
            element={<EmployerShowInterviewResult />}
          />
          <Route path="employer/setting" element={<EmployerSettingPage />} />
        </Route>
      </Routes>
    </>
  );
}

export default App;
