import React from "react";
import CandidateSidebar from "../../components/candidate/CandidateSidebar";
import Header from "../../components/Layout/Header/Header";
import UserProfile from "../../components/UserProfile";

const CandidateDashboardPage = () => {
  return (
    <div className="flex min-h-screen">
      <CandidateSidebar />
      <div className="flex flex-1 flex-col ">
        <Header />
        <div className="flex-1 p-6 bg-gray-100">
          <div className="bg-white p-6 ronded-lg shadow-md text-center">
            <h1 className="text-center text-3xl font-bold my-4">
              Candidate Dashboard
            </h1>
            <UserProfile />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDashboardPage;
