import React, { useEffect, useState, useCallback } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";

const EmployerVacancyInterview = () => {
  const [vacancy, setVacancy] = useState([]);

  const navigate = useNavigate();

  const getCompanyVacancy = useCallback(async () => {
    try {
      const { data } = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/v1/vacancy/companyVacancies`
      );
      console.log(data);
      if (data?.success) {
        setVacancy(data?.vacancies);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getCompanyVacancy();
  }, [getCompanyVacancy]);

  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) {
      return text;
    }
    return words.slice(0, wordLimit).join(" ") + " ........................";
  };

  const formatDate = (dateString) => {
    const options = { year: "numeric", month: "long", day: "numeric" };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  return (
    <>
      <div className="max-w-2xl mx-auto rounded-md overflow-hidden">
        <div className="text-lg text-gray-700 mb-4">
          <strong>Job</strong>
        </div>
        {vacancy?.length > 0 ? (
          vacancy.map((vac) => (
            <div className="p-6 border my-5" key={vac._id}>
              <h2 className="text-2xl font-bold mb-2">{vac.companyName}</h2>
              <h2 className="text-2xl font-bold mb-2">
                {vac.experiencelevel.value === 0
                  ? "Entry level"
                  : `${vac.experiencelevel.value} ${vac.experiencelevel.unit}`}
              </h2>
              <p className="text-gray-600 mb-2">
                Skills: {vac.skills.join(", ")}
              </p>
              <p className="text-gray-600 mb-2">{vac.jobTitle}</p>
              <p className="text-gray-600 mb-2">{vac.jobType}</p>
              <p
                className="text-gray-700 mb-4"
                dangerouslySetInnerHTML={{
                  __html: truncateText(vac.jobDescription, 20),
                }}
              ></p>
              <p className="py-5">
                Active from {formatDate(vac.startDate)} to{" "}
                {formatDate(vac.endDate)}
              </p>

              <div>
                <button
                  className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-700 transition duration-300 mx-2"
                  onClick={() =>
                    navigate(
                      `/dashboard/employer/applicantInfromation/${vac._id}`
                    )
                  }
                >
                  See More
                </button>

                <button
                  className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-700 transition duration-300"
                  onClick={() =>
                    navigate(`/dashboard/employer/showResult/${vac._id}`)
                  }
                >
                  Show Result
                </button>
              </div>
            </div>
          ))
        ) : (
          <div>Loading...</div>
        )}
      </div>
    </>
  );
};

export default EmployerVacancyInterview;
