import React from "react";
import teamBackground from "../../assets/about/teamBackground.png";
import adhiraj from "../../assets/about/adhiraj.png";
import francine from "../../assets/about/francine.png";
import dottedLineBelow from "../../assets/hero/dotted.png";

const TeamSection = () => {
  return (
    <>
      <div
        className="flex items-center justify-center bg-cover bg-center min-h-screen"
        style={{
          backgroundImage: `url(${teamBackground})`,
        }}
      >
        <div className="container mx-auto max-w-screen-lg" data-aos="zoom-in">
          <div className="p-8 flex flex-col items-center mb-8">
            <div className="flex items-center justify-center gap-32">
              <img src={dottedLineBelow} alt="" className="w-1/3 md:w-1/6 " />
              <img src={dottedLineBelow} alt="" className="w-1/3 md:w-1/6 " />
            </div>
            <div className="flex items-center justify-center">
              <h1 className="text-5xl font-bold ">
                <span className="text-6xl font-cinzel"> T</span>HE{" "}
                <span className="text-6xl font-cinzel"> T</span>EAM
              </h1>
            </div>
            <img
              src={dottedLineBelow}
              alt=""
              className="w-1/3 md:w-1/6 ml-40"
            />
          </div>
          <div>
            <div className="flex justify-center">
              <img
                src={francine}
                alt="Francine Gutwilik"
                className="w-40 h-40 rounded-full"
              />
              <p className="indent-8 text-2xl ml-4">
                Francine, our CEO, is a seasoned leader with over 20 years of
                experience in HR, recruitment, and startups. Having successfully
                led four companies to major exits, including LivePerson (LPSN
                Nasdaq) and DataSynapse (now TIBCO), she brings a wealth of
                strategic insight and passion for optimizing the recruitment
                process. Her vision for WizardHire is clear—empower employers to
                find the best talent faster and more fairly, while providing job
                seekers with a platform that recognizes their true potential.
              </p>
            </div>
            <div className="flex flex-row-reverse mt-8 justify-center align-center">
              <img
                src={adhiraj}
                alt="Adhiraj Dhungana"
                className="w-40 h-40 rounded-full mb-4"
              />
              <p className="indent-8 text-2xl mr-4">
                Adhiraj, our COO, is the technical genius behind the WizardHire
                platform. With a scientific background in physics, his expertise
                in coding and AI development ensures that our technology is not
                only cutting-edge but also bias-free. In a world where
                unconscious bias can skew hiring decisions, WizardHire's AI is
                designed to evaluate candidates based on their skills and
                qualifications alone—leaving behind the pitfalls of traditional
                job boards like LinkedIn and Indeed.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TeamSection;
